<template>
  <k-field-group language-prefix="user.fields">
    <k-form-dialog v-bind="$attrs" v-on="$listeners" :panels="panels">
      <template #panel.0>
        <v-row class="fill-height" justify="start">
          <v-col cols="6">
            <form-sheet class="fill-height">
              <KTextField v-model="values.firstName" field="firstName" required/>
              <KTextField v-model="values.insertion" field="insertion"/>
              <KTextField v-model="values.lastName" field="lastName" required/>
              <KTextField v-model="values.function" field="function"/>
              <KTextField v-model="values.phone" field="phone"/>
              <KTextField v-model="values.email" field="email" required/>
              <StoreAutocomplete v-model="values.storeOwner" field="stores" multiple/>
              <KCheckbox v-model="values.isActive" field="isActive"/>
              <KCheckbox v-model="values.isAdmin" field="isAdmin"/>
            </form-sheet>
          </v-col>
          <v-col cols="6">
            <form-sheet class="fill-height">
              <KTextField v-model="values.company" field="company"/>
              <KSelect v-model="values.language.locale" :items="locales" field="locale" required/>
            </form-sheet>
          </v-col>
        </v-row>
      </template>
      <template #panel.1>
        <v-row class="fill-height" justify="start">
          <v-col cols="6">
            <form-sheet class="fill-height">
              <RoleAutocomplete
                  v-model="values.roleId"
                  :roleId="values.id"
                  :return-object="false"
                  field="role"
                  item-text="name"
                  item-value="id"
              />
              <h4 class="my-2 text-uppercase">{{ $t('user.roleAndRegions.regions') }}</h4>
              <RegionTreeView v-model="values.selectedStores" :items="items"/>
            </form-sheet>
          </v-col>
        </v-row>
      </template>
      <template #panel.2>
        <v-row class="fill-height" justify="start">
          <v-col cols="6">
            <form-sheet class="fill-height">              
              <v-row v-for="(notification, index) in notifications.filter(notification => notification.isActive)"
                  :key="index"
                  class="d-flex align-center mb-0">
                <v-col cols="9">
                  <span class="text-subtitle-2 font-weight-regular">{{ $t(`notifications.notificationTitles.${notification.name}`) }}</span>
                </v-col>
                <v-col cols="3" class="d-flex justify-end">
                <v-tooltip top :disabled="!notification.isSystemNotification">
                  <template v-slot:activator="{ on }">
                    <div v-on="on">
                      <v-switch v-if="notification.isSystemNotification" class="mt-n2"
                        v-model="notification.isSystemNotification"
                        disabled
                        hide-details
                      ></v-switch>
                      <v-switch v-else class="mt-n2"
                        :value="notification.id"
                        v-model="values.activeNotificationTypeIds"
                        hide-details
                      ></v-switch>
                    </div>
                  </template>
                  <span>{{ $t('notifications.notEditable') }}</span>
                </v-tooltip>
                </v-col>
              </v-row>
            </form-sheet>
          </v-col>
        </v-row>
      </template>
    </k-form-dialog>
  </k-field-group>
</template>

<script>
import {locales} from '@/application/enums/locale.js'
import KCheckbox from '@/components/crud/fields/KCheckbox.vue'
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue'
import KSelect from '@/components/crud/fields/KSelect.vue'
import KTextField from '@/components/crud/fields/KTextField.vue'
import KFormDialog from '@/components/crud/KFormDialog.vue'
import FormSheet from '@/components/FormSheet.vue'
import StoreAutocomplete from '@/modules/store/components/StoreAutocomplete.vue'
import RoleAutocomplete from '@/modules/user/components/RoleAutocomplete.vue'
import RegionTreeView from '@/modules/user/components/RegionTreeView.vue'

export default {
  name: 'UserForm',
  components: {
    StoreAutocomplete,
    KSelect,
    KCheckbox,
    FormSheet,
    KFieldGroup,
    KTextField,
    KFormDialog,
    RoleAutocomplete,
    RegionTreeView,
  },
  props: {
    values: {
      type: Object,
    },
  },
  computed: {
    panels() {
      return [{name: this.$t('global.defaultTabName')}, {name: this.$t('user.roleAndRegions.tabName')}, {name: this.$t('user.notifications.tabName')}]
    },
    locales() {
      return Object.values(locales).map((locale) => ({
        text: this.$t(`locale.${locale}`),
        value: locale,
      }))
    },
    items() {
      if (this.values?.regions?.regions) {
        return [...this.values.regions.regions, ...this.values.regions.stores]
      }
      return []
    },
    notifications() {
      if (this.values?.notifications) {
        return this.values.notifications
      }
      return []
    }
  },
  updated() {
    if (this.items.length > 0) {
      this.values.selectedStores = this.values.regions.regions
        .concat({stores: this.values.regions.stores})
        .map((region) => region.stores.filter((store) => store.isSelected).map((store) => store.id))
        .flat()
    }
  },
}
</script>
